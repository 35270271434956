<template>
  <div class="loader-container" v-if="loading">
    <sync-loader
      class="loader-container"
      :loading="loading"
      :color="'#1e1e1e'"
      :size="'18px'"
    />
  </div>
  <section v-else>
    <div v-for="(work, i) in works" :key="work.id" class="project">
      <img v-if="work.files.find(file => !file.url.includes('vimeo'))" :src="`${urlFrom(work)}`" :alt="'Project ' + i" />
      <div v-else class="work_banner">
        <h2>{{ work.name }}</h2>
      </div>
      <span>{{ work.name }}</span>
      <div :class="'more-options position-relative wrap-project-' + i">
        <div class="btn-group dropright">
          <button
            type="button"
            class="btn btn-secondary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img src="/images/edit.svg" alt="" class="position-relative" />
          </button>
          <div class="dropdown-menu">
            <ul>
              <li @click="handleModal('project', work.id)">
                {{ $t("dash.profile_2.editar") }}
              </li>
              <li href="javascript:;" @click="deleteProject(work)">
                {{ $t("dash.profile_2.excluir") }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="add"
      @click="handleModal('create-project')"
      data-toggle="tooltip"
      data-placement="top"
      :title="$t('dash.profile_2.publish_projects_tip')"
    >
      <div class="image-container">
        <img src="/images/plus.png" alt="Adicionar projeto" />
      </div>
    </div>
  </section>
</template>

<script>
import SyncLoader from "vue-spinner/src/SyncLoader.vue";

export default {
  props: ["user"],
  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      loading: true,
      works: [],
    };
  },
  async mounted() {
    // const me = JSON.parse(localStorage.getItem("@creators:user"));
    // const me = this.$store.state.me;
    try {
      const response = await axios({
        method: "GET",
        url: `/api/v1/works?user=${this.user}`,
      });
      this.works = response.data.data;
      this.loading = false;
    } catch (err) {
      console.log(err);
    }
  },
  components: {
    SyncLoader,
  },
  methods: {
    urlFrom(work) {
      if (work) {
        if (work.files.length) {
          const workFile = work.files.find(file => !file.url.includes('vimeo'))
          return workFile ? workFile.url : ''
        }
        if (work.file) return "/" + work.file;
      }
      return "";
    },

    handleModal(type, work) {
      this.$emit("scroll", true);
      this.$emit("open-modal", type);
      this.$emit("project", work);
    },

    async deleteProject(one) {
      var _this = this;
      let index = _this.works
        .map((e) => {
          return e.id;
        })
        .indexOf(one.id);

      if (confirm("Excluir este projeto?")) {
        if (index > -1) {
          const response = await axios({
            method: "DELETE",
            url: `/api/v1/works/${one.id}`,
            headers: {
              Authorization: "Bearer " + _this.TOKEN,
            },
          });
          if (!response.data.error) {
            _this.works.splice(index, 1);
          } else {
            console.log(response.data.error);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes loading {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loader-container {
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
section {
  animation-timing-function: ease-in-out;
  animation-name: loading;
  animation-duration: 0.5s;
  max-width: 1120px;
  margin: 0 auto;
  display: grid;
  grid-gap: 20px;
  padding: 40px 0;
  grid-template-columns: repeat(3, 1fr);
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 20px;
  }
  .add {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    padding-bottom: 75%;
    border-radius: 10px;
    background-color: #1e1e1e;
    display: flex;
    .image-container {
      position: absolute;
      width: 20%;
      left: 50%;
      top: 50%;
      margin-left: -10%;
      margin-top: -10%;
    }
  }
  .project {
    // cursor: pointer;
    position: relative;
    overflow: hidden;
    padding-bottom: 75%;
    border-radius: 10px;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .work_banner {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #1e1e1e;
      display: flex;
      align-items: center;
      justify-content: center;

      h2 {
        text-align: center;
        color: #ffffff;
        font-size: 16px;
      }
    }
    span {
      position: absolute;
      left: 0;
      bottom: -30px;
      width: 100%;
      color: #ffffff;
      background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
      padding: 10px 10px 5px 10px;
      transition: all 0.4s ease-in-out;
    }
    &:hover {
      span {
        bottom: 0;
      }
    }
  }
  img {
    width: 100%;
  }
}
</style>
