<template>
  <section>
    <div class="account-section">
      <img class="avatar" :src="me.avatar ? '/' + me.avatar : '/images/avatar.jpg'" />
      <p class="name">{{ me.name }}</p>
    </div>
    <form>
      <home-input
      :label='$t("dash.profile_2.project_title")'
      name="title"
      type="text"
      maxlength="100"
      :value="title"
      @change="handleChange"
      />
      <span class="title-error-txt purple"></span>

      <!-- <home-input
      :label='$t("dash.profile_2.project_desc")'
      name="description"
      type="text"
      :value="description"
      @change="handleChange"
      /> -->

      <div class="input-container">
        <textarea
          id="textarea-description"
          :class="{ filled: description }"
          name="description"
          v-model="description"
          rows="4"
          maxlength="400"
          @change="handleChange"
           />
        <label for="textarea-description">{{ $t("dash.profile_2.project_desc") }}</label>
      </div>
      <span class="desc-error-txt purple"></span>

      <div class="url_container">
        <home-input
        label="URL video do Vimeo (Opcional)"
        name="video_url"
        type="text"
        maxlength="100"
        class="video_url-input"
        :value="video_url"
        @change="handleChange"
        />
        <img
        v-show="video_url"
        class="edit-icon"
        src="/images/checkEdit.svg"
        @click="handleUrlChange"
        />
      </div>

      <span class="video_url-error-txt purple"></span>

      <div class="addSection">
        <div
          v-for="(file, index) in attachments" :key="index"
          v-if="forceRender"
          :class="typeof file === 'string' ? 'vimeo-player' : 'imageBox'"
          :style="typeof file === 'string' ? '' : {'backgroundSize': 'cover', 'backgroundImage': backgroundImage(file)}"
          :id="typeof file === 'string' ? `vimeo-player-${file}` : file.id"
        >
          <div :class="typeof file === 'string' ? 'video-menu-controls' : 'menu-controls'">
            <div class="btn-circle-inner mt-2 ml-2" @click="typeof file === 'string' ? removeVideo(index) : removeAttachment(file)">
              <img src="/images/modal/close.svg" />
            </div>

            <div class="btn-circle-inner mt-2 ml-2" @click="moveAttachmentDown(file, index)" v-if="attachments.length > 1 && index < attachments.length - 1">
              <img src="/images/modal/arrow_down.svg" />
            </div>

            <div class="btn-circle-inner mt-2 ml-2" @click="moveAttachmentUp(index)" v-if="attachments.length > 1 && index > 0">
              <img src="/images/modal/arrow_up.svg" />
            </div>
          </div>
        </div>
        <div id='test-player' style="display: none;"></div>

        <p>{{ $t("dash.profile_2.up_image") }}</p>
        <div ref="imagePreview" class="imageBox" @click="uploadImage()" />
        <input type="file" accept="image/*" ref="inputImage" @change='handleAttachmentUpload' multiple />
        <span class="img-error-txt purple"></span>
      </div>
    </form>
    <div class="bottom-container">
      <div class="buttons-container">
        <home-button type="primary" @click.native="submitForm()">
          {{ $t("dash.salvar") }}
        </home-button>
      </div>
    </div>
    <div class="image-container">
      <img class="creators-logo" src="/images/C.png" alt="Logo creators" />
    </div>
  </section>
</template>

<script>
import Player from '@vimeo/player'

export default {
  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      title: "",
      description: "",
      attachments: [],
      video_url: "",
      links: [],
      forceRender: true,
      testPlayer: null
    };
  },
  watch: {
    attachments: function() {
      this.$nextTick(() => {
        if (this.attachments.length) {
          this.attachments.forEach((link) => {
            if (typeof link === 'string') {
              const options = {
                url: link,
                loop: true,
                title: false,
                byline: false,
                portrait: false,
              }

              const player = new Player(`vimeo-player-${link}`, options)
            }
          })
        }
      })
    }
  },
  methods: {
    backgroundImage(file) {
      return `url('${URL.createObjectURL(file)}')`;
    },
    // changeImage(e) {
    //   const { imagePreview } = this.$refs;
    //   imagePreview.style.backgroundImage = `url('${URL.createObjectURL(event.target.files[0])}')`
    //   imagePreview.style.backgroundSize = 'cover'
    // },

    uploadImage() {
      const { inputImage } = this.$refs;
      inputImage.click();
    },
    handleChange(field, value) {
      this[field] = value;
    },

    async handleUrlChange() {
      try {
        if (this.attachments.includes(this.video_url)) {
          $('.video_url-error-txt').text('URL já cadastrada')
          return
        }

        if (!this.video_url) {
          $('.video_url-error-txt').text('')
          return
        }

        const vimeoRegex = /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/
        const vimeoUrlParams = this.video_url.match(vimeoRegex)

        if (vimeoUrlParams && vimeoUrlParams[3]) {
          this.testPlayer = new Player(`test-player`, { url: this.video_url })
          await this.testPlayer.ready()
        } else {
          $('.video_url-error-txt').text('Url inválida')
          $('.video_url-error-txt').addClass('show')
          return
        }

        $('.video_url-error-txt').text('')
        await this.testPlayer.destroy()
        this.attachments = [...this.attachments, this.video_url]
        this.video_url = ''
      } catch(err) {
        $('.video_url-error-txt').text('Url inválida')
        $('.video_url-error-txt').addClass('show')

        await this.testPlayer.destroy()
      }
    },

    removeVideo(index) {
      this.forceRender = false
      setTimeout(() => {
        this.attachments.splice(index, 1)
        this.forceRender = true

        this.$nextTick(() => {
          this.attachments.forEach(link => {
            if (typeof link === 'string') {
              const options = {
                url: link,
                loop: true,
                title: false,
                byline: false,
                portrait: false,
              }

              const player = new Player(`vimeo-player-${link}`, options)
            }
          })
        })
      }, 0)
    },

    handleAttachmentUpload(ev) {
      Array.from(this.$refs.inputImage.files).forEach(file => this.attachments.push(file))
      ev.target.value = '';
    },

    removeAttachment: function(a) {
      let index = this.attachments.map((e) => {return e.name}).indexOf(a.name);
      if (index > -1) {
        this.attachments.splice(index, 1);
      }
    },

    moveAttachmentUp: function(fileIndex) {
      if (fileIndex > 0) {
        this.forceRender = false
        setTimeout(() => {
          const previousPositionFile = this.attachments[fileIndex - 1]
          this.attachments.splice(fileIndex - 1, 1)
          this.attachments.splice(fileIndex, 0, previousPositionFile)

          this.forceRender = true

          this.$nextTick(() => {
            this.attachments.forEach(link => {
              if (typeof link === 'string') {
                const options = {
                  url: link,
                  loop: true,
                  title: false,
                  byline: false,
                  portrait: false,
                }

                const player = new Player(`vimeo-player-${link}`, options)
              }
            })
          })
        }, 0)
      }
    },

    moveAttachmentDown: function(file, fileIndex) {
        if (fileIndex < this.attachments.length - 1 && fileIndex >= 0) {
        this.forceRender = false
        setTimeout(() => {
          this.attachments.splice(fileIndex, 1)
          this.attachments.splice(fileIndex + 1, 0, file)

          this.forceRender = true

          this.$nextTick(() => {
            this.attachments.forEach(link => {
              if (typeof link === 'string') {
                const options = {
                  url: link,
                  loop: true,
                  title: false,
                  byline: false,
                  portrait: false,
                }

                const player = new Player(`vimeo-player-${link}`, options)
              }
            })
          })
        }, 0)
      }
    },

    async submitForm() {
      var _this = this;
      const formData = new FormData();
      formData.append("name", _this.title);
      if (_this.description) formData.append("notes", _this.description);
      if (_this.links.length) {
        _this.links.forEach(link => {
          formData.append("links[]", link)
        })
      }
      if (_this.attachments.length) {
        for (let i = 0; i < _this.attachments.length; i++) {
          if (typeof _this.attachments[i] == 'string') {
            formData.append("links[]", _this.attachments[i])
          } else {
            formData.append("files[]", _this.attachments[i]);
          }
        }
      } else {
        $('.img-error-txt').text('Faça upload de pelo menos 1 imagem');
        // return;
      }
      if (  $('input[name="title"]').val() === "" ) {
        $('.title-error-txt').text('Escreva um título para o projeto');
        $('.title-error-txt').addClass('show');
        // return;
      }

      const response = await axios({
        method: "POST",
        url: "/api/v1/works",
        headers: {
          Authorization: "Bearer " + _this.TOKEN,
        },
        data: formData
      });
      if (response.data.error) {
        console.log(response.data.error);
      } else {
        this.$router.go();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
section {
  .title-error-txt, .desc-error-txt {
    margin: 0;
    padding: 0;
    margin-top: -28px;
    display: none;
    margin-bottom: -32px;
    &.show {
      display: block;
    }
  }
@media screen and (max-width: 768px) {
  height: calc(100%);
}

position: relative;
font-family: "SF Pro Display";
min-height: 100vh;
.account-section {
  @media screen and (max-width: 768px) {
    margin-bottom: 0px;
    margin-top: 22px;
  }
  height: 48px;
  display: flex;
  align-items: center;
  margin-bottom: 132px;
  img {
    margin-right: 24px;
    height: 100%;
  }
  .name {
    color: white;
    font-size: 18px;
    line-height: 24px;
  }
}
.avatar {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  object-fit: cover;
}
form {
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
  width: 70%;
  padding: 0 10%;
  * {
    margin: 16px 0;
  }
  textarea {
    background-color: transparent;
    width: 100%;
    line-height: initial;
    font-family: "SF Pro Display";
    // font-size: 26px;
    color: #fff;
    border: 0px;
    border-bottom: 2px solid #7053d9;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  .addSection {
    width: max-content;
    margin-bottom: 30px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    input {
      display: none;
    }
    p {
      font-size: 20px;
      margin: 0;
      font-family: "SF Pro Display";
      color: white;
    }
    .imageBox {
      @media screen and (max-width: 768px) {
        margin: 10px 0;
        width: 100%;
        height: 150px;
      }
      cursor: pointer;
      background-image: url("/images/camera.png");
      margin: 31px 0;
      background-color: #7f7f7f;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 10px;
      width: 400px;
      height: 200px;
    }
  }
}
.bottom-container {
  height: auto;
  bottom: 10.5%;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 0px;
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
  button {
    margin: 0;
  }
  a {
    text-decoration: none;
  }
  position: absolute;
  @media screen and (max-width: 768px) {
    position: relative;
    bottom: 0;
  }
}
.input-container {
  position: relative;
  input, textarea {
    position: relative;
    left: 0;
    top: 0;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #ddd;
    transition: all 0.2s ease-in-out;
    padding: 10px 0 5px 0;
    color: #ffffff;
    width: 100%;
    font-size: 1.1em;
    &:-webkit-autofill {
      transition-delay: 99999s;
      -webkit-transition-delay: 99999s;
    }
    &:focus,
    &.filled {
      outline: none;
      border-bottom: 2px solid #7053d9;
      + label {
        transform: translateY(-20px);
        font-size: 0.8em;
        color: #7053d9;
      }
    }
  }
  label {
    font-size: 1em;
    transition: all 0.2s ease-in-out;
    position: absolute;
    left: 0;
    font-weight: normal;
    padding: 10px 0;
    top: 0;
    color: #ffffff;
    pointer-events: none;
  }
}
.image-container {
  margin-bottom: 0px;
  opacity: 0.4;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
    display: none;
  }
}
.creators-logo {
  width: 43px;
}

.menu-controls {
  margin-bottom: -56px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  img {
    max-height: 34px;
    max-width: 34px;
  }
}

.video-menu-controls {
  top: 0;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  z-index: 3;

  img {
    max-height: 34px;
    max-width: 34px;
  }
}

  .vimeo-player {
    position: relative;
    @media (max-width: 768px) {
      width: 100%;
      height: calc(100vw / 1.77);
      margin: 10px 0;
    }
    margin: 31px 0;
    width: 400px;
    height: calc(498px / 1.77);

    .btn-wrapper-circle {
      position: absolute;
      top: 0;
      left: 10px;
    }
  }

  .video_url-input {
    margin-bottom: 0;
  }

  .url_container {
    position: relative;
    margin: 0;
    img {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
</style>
