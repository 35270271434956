<template>
  <section>
    <div class="account-section">
      <img
        class="avatar"
        :src="me.avatar ? '/' + me.avatar : '/images/avatar.jpg'"
      />
      <p class="name">{{ author }}</p>
    </div>
    <div class="textual-content">
      <div class="title-container">
        <h1 class="title" v-if="!edit.name">{{ name }}</h1>
        <input v-else v-model="name" class="edit-input" maxlength="100" />
        <img
          class="edit-icon"
          :src="edit.name ? '/images/checkEdit.svg' : '/images/edit.svg'"
          @click="
            () => {
              handleEdit('name');
            }
          "
        />
      </div>
      <div class="notes-container">
        <p v-if="!edit.notes" class="project-notes d-inline">{{ notes }}</p>
        <textarea v-else v-model="notes" rows="5" maxlength="400" />
        <img
          class="edit-icon"
          :src="edit.notes ? '/images/checkEdit.svg' : '/images/edit.svg'"
          @click="
            () => {
              handleEdit('notes');
            }
          "
        />
      </div>
    </div>
    <div class="image-content">
      <div
        v-for="(file, index) in files"
        :key="file.id"
        :id="!file.url.includes('vimeo') ? file.id : `vimeo-player-${file.id}`"
        :class="!file.url.includes('vimeo') ? 'image-container' : 'vimeo-player'"
      >
        <div v-if="!file.single" class="btn-wrapper-circle">
          <div class="menu-controls">
            <div class="btn-circle-inner mt-2 ml-2" @click="removeOne(file)">
              <img src="/images/modal/close.svg" />
            </div>

            <div class="btn-circle-inner mt-2 ml-2"  @click="moveFileDown(file)" v-if="index !== files.length - 1">
              <img src="/images/modal/arrow_down.svg" />
            </div>

            <div class="btn-circle-inner mt-2 ml-2"  @click="moveFileUp(file)" v-if="index > 0">
              <img src="/images/modal/arrow_up.svg" />
            </div>
          </div>
        </div>
        <div v-else class="btn-wrapper-circle">
          <div class="btn-circle-inner" @click="removeSingle">
            <img src="/images/modal/close.svg" />
          </div>
        </div>
        <img v-if="!file.url.includes('vimeo')" :src="file.url" alt="first picture" />
      </div>
      <div id='test-player' style="display: none;"></div>
    </div>
    <form>
      <div class="addSection">
        <div class="url_container">
          <home-input
          label="URL video do Vimeo (Opcional)"
          name="video_url"
          type="text"
          maxlength="100"
          class="video_url-input"
          :value="video_url"
          @change="handleChange"
          />
          <img
          v-show="video_url"
          class="edit-icon"
          src="/images/checkEdit.svg"
          @click="handleUrlChange"
          />
        </div>
        <span class="video_url-error-txt purple"></span>

        <p>{{ $t("dash.profile_2.up_image") }}</p>
        <div
          v-for="file in attachments"
          class="imageBox"
          :key="file.id"
          :style="{
            backgroundSize: 'cover',
            backgroundImage: backgroundImage(file),
          }"
        >
          <div class="btn-wrapper-circle">
            <div class="btn-circle-inner" @click="removeAttachment(file)">
              <img src="/images/modal/close.svg" />
            </div>
          </div>
        </div>
        <div ref="imagePreview" class="imageBox" @click="uploadImage()" />
        <input
          type="file"
          accept="image/*"
          ref="inputImage"
          @change="handleAttachmentUpload"
          multiple
        />
      </div>
    </form>

    <div class="image-content">
      <div class="image-container">
        <img class="creators-logo" src="/images/C.png" alt="Logo creators" />
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import Player from '@vimeo/player'

export default {
  components: {
    Vue,
    VueToast,
  },
  props: {
    project: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      work: {
        files: [],
      },
      author: "",
      // files: [],
      name: "",
      notes: "",
      avatar: "",
      edit: {
        name: false,
        notes: false,
      },
      attachments: [],
      video_url: "",
      testPlayer: null
    };
  },
  updated() {
    this.$nextTick(function () {
      this.work.files.forEach(file => {
        if (file.url.includes('vimeo')) {
          const options = {
            loop: true,
            title: false,
            byline: false,
            portrait: false
          }

          const player = new Player(`vimeo-player-${file.id}`, {...options, url: file.url})
        }
      })
    })
  },
  async beforeMount() {
    var _this = this;
    // const me = JSON.parse(localStorage.getItem("@creators:user"));
    const response = await axios({
      method: "GET",
      url: `/api/v1/works/${this.project}`,
    });
    if (!response.data.error) {
      this.author = _this.me.name;
      this.work = response.data.data;
      this.work.files = response.data.data.files.sort((fileA, fileB) => fileA.order - fileB.order);
      this.name = response.data.data.name;
      this.notes = response.data.data.notes;
    } else {
      console.log(response.data.error);
    }
  },
  computed: {
    files() {
      if (this.work) {
        if (this.work.files.length) return this.work.files;
        if (this.work.file)
          return [{ url: "/" + this.work.file, single: true }];
      }
      return [];
    },
  },
  methods: {
    openToast() {
      this.$toast.open({
        message: `<p class="white">${this.$t(
          "newbriefing.project.changesCompleted"
        )}</p>`,
        position: "bottom-left",
        duration: 3000,
      });
    },

    async handleEdit(sectionName) {
      var _this = this;
      if (this.edit[sectionName]) {
        const response = await axios({
          method: "POST",
          url: `/api/v1/works/${this.project}`,
          headers: {
            Authorization: "Bearer " + _this.TOKEN,
          },
          data: {
            _method: "PUT",
            [sectionName]: this[sectionName],
          },
        }).then(() => this.openToast());
      }
      this.edit[sectionName] = !this.edit[sectionName];
    },

    backgroundImage(file) {
      return `url('${URL.createObjectURL(file)}')`;
    },

    uploadImage() {
      const { inputImage } = this.$refs;
      inputImage.click();
    },

    handleChange(field, value) {
      this[field] = value;
    },

    async handleAttachmentUpload(ev) {
      const formData = new FormData();
      Array.from(this.$refs.inputImage.files).forEach((file) => {
        if ((file.size/1024/1024) > 5) {
          alert('Arquivo maior que o permitido, reduzir e fazer novamente o upload.')
        } else {
          formData.append("files[]", file);
        }
      });

      formData.append("_method", "PUT");
      const response = await axios({
        method: "POST",
        url: "/api/v1/works/" + this.project,
        headers: {
          Authorization: "Bearer " + this.TOKEN,
        },
        data: formData,
      });

      if (response.data.error) {
        alert(response.data.error);
        return;
      }

      this.work = response.data.data;
      ev.target.value = "";
    },

    async removeOne(one) {
      var _this = this;
      let index = _this.files
        .map((e) => {
          return e.name;
        })
        .indexOf(one.name);
      if (index > -1) {
        const response = await axios({
          method: "POST",
          url: `/api/v1/works/${_this.project}/files/${one.id}`,
          headers: {
            Authorization: "Bearer " + _this.TOKEN,
          },
          data: {
            _method: "PUT",
          },
        });
        if (!response.data.error) {
          _this.files.splice(index, 1);
        } else {
          console.log(response.data.error);
        }
        this.openToast();
      }
    },

    async removeSingle() {
      var _this = this;
      const response = await axios({
        method: "POST",
        url: `/api/v1/works/${this.project}`,
        headers: {
          Authorization: "Bearer " + _this.TOKEN,
        },
        data: {
          _method: "PUT",
          file: null,
        },
      });
      _this.work.file = false;
      this.openToast();
    },

    async moveFileUp(file) {
      const fileIndex = this.files.findIndex(image => image.name === file.name)

      if (fileIndex > 0) {
        const previousPositionFile = this.files[fileIndex - 1]

        this.files.splice(fileIndex - 1, 1)
        this.files.splice(fileIndex, 0, previousPositionFile)

        const response = await axios({
          method: 'POST',
          url: `/api/v1/works/${this.project}/order`,
          headers: {
            Authorization: `Bearer ${this.TOKEN}`,
          },
          data: {
            _method: "POST",
            order: this.files.map(file => file.id)
          }
        })

        if (!response.data.error) {
          this.openToast()
        }
      }
    },

    async moveFileDown(file) {
      const fileIndex = this.files.findIndex(image => image.name === file.name)

      if (fileIndex < this.files.length - 1 && fileIndex >= 0) {
        this.files.splice(fileIndex, 1)
        this.files.splice(fileIndex + 1, 0, file)
      }

      const response = await axios({
        method: 'POST',
        url: `/api/v1/works/${this.project}/order`,
        headers: {
          Authorization: `Bearer ${this.TOKEN}`,
        },
        data: {
          _method: "POST",
          order: this.files.map(file => file.id)
        }
      })

      if (!response.data.error) {
        this.openToast()
      }
    },
    async handleUrlChange() {
      try {
        if (this.work.files.map(file => file.url).includes(this.video_url)) {
          $('.video_url-error-txt').text('URL já cadastrada')
          return
        }

        if (!this.video_url) {
          $('.video_url-error-txt').text('')
          return
        }

        const vimeoRegex = /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/
        const vimeoUrlParams = this.video_url.match(vimeoRegex)

        if (vimeoUrlParams && vimeoUrlParams[3]) {
          this.testPlayer = new Player(`test-player`, { url: this.video_url })
          await this.testPlayer.ready()
        } else {
          $('.video_url-error-txt').text('Url inválida')
          $('.video_url-error-txt').addClass('show')
          return
        }

        const formData = new FormData()

        formData.append("links[]", this.video_url)
        formData.append("_method", "PUT")

        const res = await axios({
          method: "POST",
          url: "/api/v1/works/" + this.project,
          headers: {
            Authorization: "Bearer " + this.TOKEN,
          },
          data: formData,
        })

        $('.video_url-error-txt').text('')
        this.work = res.data.data;
        this.video_url = ''
        this.openToast()
        await this.testPlayer.destroy()
      } catch(err) {
        $('.video_url-error-txt').text('Url inválida')
        $('.video_url-error-txt').addClass('show')

        await this.testPlayer.destroy()
      }
    },
  }

};
</script>

<style lang="scss" scoped>
section {
  font-family: "SF Pro Display";
  height: 100%;
  .account-section {
    @media screen and (max-width: 768px) {
      margin-top: 22px;
      margin-bottom: 51px;
    }
    height: 48px;
    display: flex;
    align-items: center;
    margin-bottom: 132px;
    img {
      margin-right: 24px;
      height: 100%;
    }
    .name {
      color: white;
      font-size: 18px;
      line-height: 24px;
    }
  }
  .textual-content {
    @media screen and (max-width: 768px) {
      padding: 0;
    }
    padding: 0 10%;
    margin-bottom: 28px;
    .title {
      @media screen and (max-width: 768px) {
        font-size: 24px;
      }
      display: inline-block;
      color: white;
      font-size: 32px;
      line-height: 38px;
    }
    .project-notes {
      @media screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 28px;
      }
      color: white;
      font-family: "SF Pro Display";
      font-size: 32px;
      line-height: 38px;
    }
  }
  .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  .edit-input {
    background-color: transparent;
    color: white;
    border: 0px;
    border-bottom: 2px solid #7053d9;
    font-family: "SF Pro Display";
    font-size: 16px;
    width: auto;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  textarea {
    background-color: transparent;
    width: 90%;
    line-height: initial;
    font-family: "SF Pro Display";
    font-size: 26px;
    color: #fff;
    border: 0px;
    border-bottom: 2px solid #7053d9;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  .image-content {
    @media screen and (max-width: 768px) {
      width: 100vw;
      margin-left: -16px;
      margin-bottom: 70px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    .image-container {
      @media screen and (max-width: 768px) {
        margin: 0;
        width: 100%;
      }
      margin: 28px 0;
      img {
        width: 100%;
      }
      .creators-logo {
        @media screen and (max-width: 768px) {
          width: 0px;
        }
        width: 43px;
        opacity: 0.4;
      }
    }
  }
  .avatar {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    object-fit: cover;
  }
  form {
    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 0;
    }
    width: 70%;
    padding: 0 10%;
    * {
      margin: 48px 0;
    }
    .addSection {
      width: max-content;
      margin-bottom: 30px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      input {
        display: none;
      }
      p {
        font-size: 20px;
        margin: 0;
        font-family: "SF Pro Display";
        color: white;
      }
      .imageBox {
        @media screen and (max-width: 768px) {
          margin: 10px 0;
          width: 100%;
          height: 150px;
        }
        cursor: pointer;
        background-image: url("/images/camera.png");
        margin: 31px 0;
        background-color: #7f7f7f;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 10px;
        width: 400px;
        height: 200px;
      }
    }
  }

  .vimeo-player {
    @media (max-width: 768px) {
      height: calc(100vw / 1.77);
      padding: 0;
      margin-top: 12px;
    }

    width: 100%;
    height: calc(80vw / 1.77);
    padding: 0 10%;
  }

  .menu-controls {
    margin-bottom: -56px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    z-index: 3;

    img {
      max-height: 34px;
      max-width: 34px;
    }
  }

  .video_url-input {
    margin-bottom: 0;
  }

  .video_url-error-txt {
    margin-bottom: 24px;
  }

  .url_container {
    position: relative;
    margin: 0;
    img {
      position: absolute;
      bottom: 20px;
      right: 0;
      margin: 0;
    }
  }
}
</style>
